function EditableTextField(props) {
  return (
    <>
      <div className="formLabel">
        <label>{props.label}</label>
      </div>
      <input
        className={props.modified ? "textField modified" : "textField"}
        disabled={!props.editable}
        name={props.fieldName}
        type={props.fieldType || "text"}
        ref={props.useRef}
        value={props.fieldValue}
        onKeyUp={props.useKeyUp}
        onChange={e => {
          e.preventDefault();
          props.setValue(e.target.value);
        }} />
    </>
  );
}

function EditableNumberField(props) {
  return (
    <EditableTextField
      label={props.label}
      modified={props.modified}
      editable={props.editable}
      fieldName={props.fieldName}
      fieldType="number"
      useRef={props.useRef}
      fieldValue={props.fieldValue.toString()}
      useKeyUp={props.useKeyUp}
      setValue={v => { props.setValue(parseInt(v || "0")) }} />
  );
}


export { EditableNumberField, EditableTextField };