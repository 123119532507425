import React from "react";

class ContactInfo extends React.Component {
  render() {
    if (this.props.loggedIn === true) {
      return (
        <div className="centered">
          <br></br>
          <p className="bolded">Questions?</p>
          <p>Please feel free to reach out to us at info@educationexcellenceinitiative.org</p>
          <br></br>
          <p>We'd love to hear your feedback about this newly developing website!</p>
          <a className="textLink" href="https://docs.google.com/forms/d/e/1FAIpQLScWZlWVvnMYhkSV5wgj4fRAs0Yrd1ByGg5vkkhz0lmno5ZViw/viewform?usp=sf_link" target="_blank" rel="noreferrer">Click here to fill out the survey</a>
        </div>
      );
    }
    return <></>;
  }
}

export { ContactInfo };