import { createContext, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { LoginContext } from "./LoginContext";
import { useUrlParam } from "./useUrlParam";


const AdminContext = createContext(null);


function AdminToolbar(props) {
  const [, setSearchParams] = useSearchParams();
  const loginContext = useContext(LoginContext);

  const isAdmin = (
    loginContext.loggedIn && loginContext.userRoles.includes("admin")
  );

  useEffect(() => {
    let ignore = false;

    if (!isAdmin && !ignore) {
      setSearchParams(p => {
        p.delete("singlePage");
        p.delete("showLoading");
        p.delete("role");
        p.delete("courseAccessIds");
        return p;
      });
    }

    return () => {
      ignore = true;
    };
  }, [isAdmin, setSearchParams]);

  if (!isAdmin) {
    return <></>;
  }

  const adminContext = {
    courseAccessIds: loginContext.courseAccessIds,
    setCourseAccessIds: loginContext.setCourseAccessIds,
    singlePage: props.singlePage,
    setSinglePage: props.setSinglePage,
    showLoading: props.showLoading,
    setShowLoading: props.setShowLoading,
    roles: loginContext.userRoles,
    activeRole: props.activeRole,
    setActiveRole: props.setActiveRole,
  };

  return (
    <div className="adminPanel">
      <label>Admin Tools</label>
      <AdminContext.Provider value={adminContext}>
        <SinglePage />
        <ShowLoading />
        <ActiveRole />
        <CourseAccessIds />
      </AdminContext.Provider>
    </div>
  );
}

function SinglePage() {
  const adminContext = useContext(AdminContext);
  const [
    urlParam, setUrlParam,
  ] = useUrlParam("singlePage", adminContext.singlePage);
  const classes = urlParam
    ? "optionBlock interactive isSelected"
    : "optionBlock interactive";

  return (
    <div className="adminBlock">
      <div
        className={classes}
        onClick={
          (event) => {
            event.preventDefault();
            setUrlParam(!urlParam);
            adminContext.setSinglePage(!urlParam);
          }
        }
      >
        Single Page
      </div>
    </div>
  );
}

function ShowLoading() {
  const adminContext = useContext(AdminContext);
  const [
    urlParam, setUrlParam,
  ] = useUrlParam("showLoading", adminContext.showLoading);
  const classes = urlParam
    ? "optionBlock interactive isSelected"
    : "optionBlock interactive";

  return (
    <div className="adminBlock">
      <div
        className={classes}
        onClick={
          (event) => {
            event.preventDefault();
            setUrlParam(!urlParam);
            adminContext.setShowLoading(!urlParam);
          }
        }
      >
        Show Loading
      </div>
    </div>
  );
}

function ActiveRole() {
  const adminContext = useContext(AdminContext);
  const [
    roleParam, setRoleParam,
  ] = useUrlParam("role", adminContext.activeRole);

  const roleItems = adminContext.roles.map(
    r => <option key={r} value={r}>{r}</option>
  );

  if (roleItems.length < 1) {
    return (
      <div className="adminBlock">
        <span className="errorText">No roles assigned</span>
      </div>
    );
  }

  return (
    <div className="adminBlock">
      <label>View As</label>
      <select
        className="optionBlock"
        value={roleParam}
        onChange={
          (event) => {
            setRoleParam(event.target.value);
            adminContext.setActiveRole(event.target.value);
          }
        }>
        {roleItems}
      </select>
    </div>
  );
}

function CourseAccessIds() {
  return (
    <div className="adminBlock">
      <label>Course Access Ids</label>
      <CourseAccessId accessId={9} />
      <CourseAccessId accessId={10} />
      <CourseAccessId accessId={11} />
      <CourseAccessId accessId={12} />
    </div>
  );
}

function CourseAccessId(props) {
  const adminContext = useContext(AdminContext);
  const [urlParam, setUrlParam] = useUrlParam(
    "courseAccessIds", adminContext.courseAccessIds);
  const isSelected = urlParam.includes(props.accessId);
  const classes = isSelected
    ? "optionBlock interactive isSelected"
    : "optionBlock interactive";

  const handleClick = (event) => {
    event.preventDefault();
    let newIds = [];
    if (isSelected) {
      newIds = adminContext.courseAccessIds.filter(
        accessId => accessId !== props.accessId
      );
    } else {
      newIds = adminContext.courseAccessIds.concat([props.accessId]);
    }
    setUrlParam(newIds);
    adminContext.setCourseAccessIds(newIds);
  };

  return (
    <div className={classes} onClick={handleClick}>{props.accessId}</div>
  );
}

export { AdminToolbar };
