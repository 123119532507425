import { useContext } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import { FirebaseImage } from "./FirebaseImage";
import { Login } from "./Login";
import { LoginContext } from "./LoginContext";

function Home(props) {
  const loginContext = useContext(LoginContext);
  const { shouldLoad } = props;

  if (!shouldLoad || loginContext.loggedIn) {
    return <></>;
  }

  return (
    <div className="mainContent">
      <Outlet />
      <Routes>
        <Route index={true} element={<HomePage />} />
        <Route path="login/*" element={<Login />} />
      </Routes>
    </div>
  );
}

function HomePage() {
  const navigate = useNavigate();

  return (
    <>
      <FirebaseImage
        imageid="loginHomePagePhoto"
        alt={
          "Students tossing graduation caps while standing on hill in sunset"
        }
        className="largeLandingPhoto" />
      <div>
        <p className="landingBlurb">
          Education Excellence Initiative (EEI) is a 501(c)(3) non-profit
          organization dedicated to making education planning services
          available to EVERYONE. Whether you need help navigating school,
          applying for a program, or simply understanding different parts of
          the education “game”, we have resources for you.
        </p>
      </div>
      <button onClick={e => {
        e.preventDefault();
        navigate("login");
      }}>Login</button>
      <div>
        <p>Questions? Email us at: info@educationexcellenceinitiative.org</p>
      </div>
      <div>
        <h2>Our Story</h2>
        <p>
          In 2016, Zachariah Seiden founded Education Encompassed with a
          vision of creating a better education planning company. In 2020,
          four years later, a global pandemic put the world into chaos.
          During this time, Zachariah realized two things: 1{"\x29"} the field of
          education was going to change, and 2{"\x29"} education planning needed
          to be more accessible.
        </p>
        <p>
          In December of 2020, Zachariah founded the Education Excellence
          Initiative. The mission is simple: make education planning
          services accessible to as many people as possible — all over the
          world — regardless of financial background. Building upon what
          Zachariah created with Education Encompassed, the Education
          Excellence Initiative seeks to do just that.
        </p>
      </div>
    </>
  );
}


export { Home };