import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";


/*
  Manages the URL state for a particular parameter, creating it, if necessary,
  on behalf of the application. In this context, the URL values, if provided,
  will override the passed in defaultValue
*/
function useUrlParam(name, defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParam = searchParams.get(name);

  const setUrlParam = useCallback((newValue) => {
    setSearchParams(p => {
      p.set(name, JSON.stringify(newValue));
      return p;
    });
  }, [name, setSearchParams]);

  useEffect(() => {
    let ignore = false;

    if (urlParam === null && !ignore) {
      setUrlParam(defaultValue);
    }

    return () => {
      ignore = true;
    };
  }, [urlParam, defaultValue, setUrlParam]);

  if (urlParam === null) {
    return [defaultValue, setUrlParam];
  }

  return [JSON.parse(urlParam), setUrlParam];
}

export { useUrlParam };