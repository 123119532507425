import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
} from "react-router-dom";

import { AdminToolbar } from './AdminToolbar';
import { ContactInfo } from './ContactInfo';
import { FirebaseImage } from './FirebaseImage';
import { Home } from './Home';
import {
  LegalDisclaimers,
} from './LegalDisclaimers';
import { LoginContext, useLoginContext } from "./LoginContext";
import { MainPage } from "./MainPage";
import { MaybeRedirect } from "./MaybeRedirect";

import './App.css';


function App() {
  const [activeRole, setActiveRole] = useState("");
  const [singlePage, setSinglePage] = useState(false);
  const [firstLoadPaused, setFirstLoadPaused] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const loginContext = useLoginContext();

  const { loggedIn } = loginContext;
  const isAdmin = (
    loginContext.loggedIn && loginContext.userRoles.includes("admin")
  );

  // wait half a second for the local state to load; if not, resume loading
  // the site in a not-logged-in context
  useEffect(() => {
    let ignore = false;
    const unmount = () => { ignore = true; };

    if (!firstLoadPaused || loggedIn) {
      return unmount;
    }

    setTimeout(() => {
      if (!ignore) {
        setFirstLoadPaused(false);
      }
    }, 500);

    return unmount;
  }, [firstLoadPaused, loggedIn]);

  useEffect(() => {
    let ignore = false;
    const unmount = () => { ignore = true; };
    const initialRole = (
      loginContext.userRoles.includes("admin") ? "admin"
        : loginContext.userRoles.length > 0 ? loginContext.userRoles[0]
          : ""
    );

    // If logged in, we can proceed, firebase has loaded
    if (loginContext.loggedIn) {
      if (!ignore) {
        setActiveRole(activeRole || initialRole);
        setFirstLoadPaused(false);
      }
      return unmount;
    }

    // If not logged in, and the first load it paused, not sure if it just
    // hasn't loaded yet, or we're not actually logged in
    if (!firstLoadPaused && !ignore) {
      setActiveRole("");
      setFirstLoadPaused(false);
    }

    return unmount;
  }, [firstLoadPaused, loginContext, activeRole]);

  return (
    <div className="contentContainer">
      <LoginContext.Provider value={loginContext}>
        <MaybeRedirect
          singlePage={isAdmin && singlePage}
          loggedIn={loginContext.loggedIn}
          isLoaded={!firstLoadPaused && !showLoading}
        />

        <AdminToolbar
          singlePage={singlePage}
          setSinglePage={setSinglePage}
          activeRole={activeRole}
          setActiveRole={setActiveRole}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
        />

        <FirebaseImage
          imageid="eeiLogo"
          alt="Education Excellence Initiative company logo"
          className="companyLogo"
        />

        <h1>Education Excellence Initiative</h1>

        <Home shouldLoad={!firstLoadPaused} />

        <MainPage
          singlePage={singlePage}
          isLoading={firstLoadPaused || showLoading}
          activeRole={activeRole}
        />

        <ContactInfo loggedIn={loginContext.loggedIn} />
        <LegalDisclaimers />
      </LoginContext.Provider>
    </div>
  );
}


const Routed = createBrowserRouter([{
  path: "/*",
  element: <App />,
}]);


export { Routed };