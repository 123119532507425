import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";

import { Storage } from "./FirebaseConfig";


function useStorageUrl(storagePath) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    let ignore = false;

    if (!storagePath) {
      if (!ignore) {
        setUrl("");
      }
      return;
    }
    console.debug(`getting url for "${storagePath}"`);
    const storageRef = ref(Storage(), storagePath);
    getDownloadURL(storageRef).then(theUrl => {
      if (!ignore) {
        setUrl(theUrl);
      }
    });

    return () => {
      ignore = true;
    };

  }, [storagePath]);

  return url;
}


export { useStorageUrl };