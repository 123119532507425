import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useReducer } from "react";

import { Db, Functions, FunctionsLocal } from "./FirebaseConfig";

const createUserFunction = httpsCallable(Functions(), "createuser");
const deleteUserFunction = httpsCallable(Functions(), "deleteuser");
const ensureMemberOfList = httpsCallable(Functions(), "ensurememberoflist");
const ensureMemberNotInList = httpsCallable(
  Functions(), "ensuremembernotinlist",
);
const updateUserFunction = httpsCallable(Functions(), "updateuser");


const SaveStatus = {
  Pending: 1,
  Done: 2,
  Error: 3,
};


function userManagerReducer(currentData, request) {
  if (request.action === "loadUser") {
    const userData = {
      uid: request.user?.uid,
      firstName: request.user?.firstName || "",
      lastName: request.user?.lastName || "",
      emailAddress: request.user?.emailAddress || "",
      graduationYear: request.user?.graduationYear || 0,
      roles: request.user?.roles || [],
      courseAccessIds: request.user?.courseAccessIds || [],
      mailingLists: request.user?.mailingLists || [],
    };
    const newData = Object.assign({}, currentData, {
      editing: true,
      currentUser: Object.assign({}, userData),
      savedUser: Object.assign({}, userData),
      saveStatus: null,
      neededOperation: "",
    });
    return newData;
  }

  else if (request.action === "newUser") {
    const userData = emtpyUser();
    const newData = Object.assign({}, currentData, {
      editing: true,
      neededOperation: "",
      saveStatus: null,
      currentUser: Object.assign({}, userData),
      savedUser: null,
    });
    return newData;
  }

  else if (request.action === "setFields") {
    const newData = Object.assign(
      {},
      currentData,
      {
        currentUser: Object.assign({}, currentData.currentUser, request.fields),
      },
    );
    return newData;
  }

  else if (request.action === "deleteUser") {
    const { savedUser } = currentData;
    let pendingSaveStatus = {};
    pendingSaveStatus[
      `account (${savedUser.emailAddress})`
    ] = SaveStatus.Pending;
    pendingSaveStatus[`db (${savedUser.emailAddress})`] = SaveStatus.Pending;
    for (const m of savedUser.mailingLists) {
      pendingSaveStatus[`list (${m})`] = SaveStatus.Pending;
    }
    const newData = Object.assign(
      {},
      currentData,
      { neededOperation: "deleteUser", saveStatus: pendingSaveStatus },
    );
    return newData;
  }

  else if (request.action === "saveUser") {
    const { currentUser, savedUser } = currentData;
    const emailAddress = savedUser?.emailAddress || currentUser.emailAddress;

    let pendingSaveStatus = {};
    pendingSaveStatus[`account (${emailAddress})`] = SaveStatus.Pending;
    pendingSaveStatus[`db (${emailAddress})`] = SaveStatus.Pending;
    const l = currentUser.mailingLists.concat(savedUser?.mailingLists || []);
    for (const m of l) {
      pendingSaveStatus[`list (${m})`] = SaveStatus.Pending;
    }
    const newData = Object.assign(
      {},
      currentData,
      { neededOperation: "saveUser", saveStatus: pendingSaveStatus },
    );
    return newData;
  }

  else if (request.action === "reset") {
    return {
      editing: false,
      currentUser: emtpyUser(),
      savedUser: null,
      saveStatus: null,
      neededOperation: "",
    };
  }

  else if (request.action === "updateSaveStatus") {
    const newData = Object.assign(
      {},
      currentData,
      {
        editing: false,
        saveStatus: Object.assign(
          {}, currentData.saveStatus || {}, request.fields,
        )
      }
    );
    return newData;
  }

  // if unknown action, just ignore
  return currentData;
}

function emtpyUser() {
  return {
    uid: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    graduationYear: 0,
    roles: [],
    courseAccessIds: [],
    mailingLists: [],
  };
}

function useUserManagerReducer() {
  const [
    userManager, umDispatcher,
  ] = useReducer(
    userManagerReducer,
    {
      editing: false,
      currentUser: emtpyUser(),
      savedUser: null,
      saveStatus: null,
      neededOperation: "",
    },
  );

  const { saveStatus, neededOperation, savedUser, currentUser } = userManager;

  const setSS = (key, val) => {
    umDispatcher({ action: "updateSaveStatus", fields: { [key]: val } });
  };

  const deleteUserFromDb = useCallback(async (user, shouldIgnore) => {
    let ignore = shouldIgnore();
    const usersCollection = collection(Db(), "users");
    const q = query(usersCollection, where("uid", "==", user.uid));
    const querySnap = await getDocs(q);

    if (querySnap.size > 1) {
      console.error(`found multiple users with same uid: ${user.uid}`);
      if (!ignore) {
        setSS(`db (${user.emailAddress})`, SaveStatus.Error);
      }
      return;
    }

    if (querySnap.size < 1) {
      console.warn(`found no users with uid: ${user.uid}`);
      if (!ignore) {
        setSS(`db (${user.emailAddress})`, SaveStatus.Done);
      }
      return;
    }

    try {
      const userSnap = querySnap.docs[0];
      console.debug(`id: ${userSnap.id}`);
      if (!ignore) {
        await deleteDoc(userSnap.ref);
        setSS(`db (${user.emailAddress})`, SaveStatus.Done);
      }
    } catch (e) {
      console.error(`error deleting document: ${e}`);
      setSS(`db (${user.emailAddress})`, SaveStatus.Error);
    }
  }, []);

  const deleteUserAccount = useCallback(async (user, shouldIgnore) => {
    try {
      if (!shouldIgnore()) {
        await deleteUserFunction({ uid: user.uid });
        setSS(`account (${user.emailAddress})`, SaveStatus.Done);
      }
    } catch (e) {
      console.error(`error deleting user account: ${e}`);
      setSS(`account (${user.emailAddress})`, SaveStatus.Error);
    }
  }, []);

  const removeFromMailingList = useCallback(
    async (email, list, shouldIgnore) => {
      console.debug(`ensuring ${email} not in ${list}`);
      let ignore = shouldIgnore();
      try {
        await ensureMemberNotInList(
          {
            dryRun: FunctionsLocal || ignore,
            member: { emailAddress: email },
            mailingList: list,
          },
        );
        if (!ignore) {
          setSS(`list (${list})`, SaveStatus.Done);
        }
      } catch (e) {
        console.error(`error removing from mailing list: ${e}`);
        if (!ignore) {
          setSS(`list (${list})`, SaveStatus.Error);
        }
      }
    },
    [],
  );

  const deleteUser = useCallback((user, shouldIgnore) => {
    console.debug(`deleting user with uid: ${user.uid}`);
    deleteUserFromDb(user, shouldIgnore);
    deleteUserAccount(user, shouldIgnore);
    for (const m of user.mailingLists) {
      removeFromMailingList(user.emailAddress, m, shouldIgnore);
    }
  }, [removeFromMailingList, deleteUserAccount, deleteUserFromDb]);

  const saveAccount = useCallback(
    async (currentUser, savedUser, shouldIgnore) => {
      let uid = "";
      let ignore = shouldIgnore();

      try {
        if (!ignore && savedUser) {
          await updateUserFunction({ current: currentUser, prev: savedUser });
          uid = savedUser.uid;
          console.debug("updated auth entry for user");
          setSS(`account (${currentUser.emailAddress})`, SaveStatus.Done);
        }
      } catch (e) {
        console.error(`error updating account: ${e}`);
        setSS(`account (${savedUser.emailAddress})`, SaveStatus.Error);
        setSS(`db (${savedUser.emailAddress})`, SaveStatus.Error);
        return;
      }

      try {
        if (!ignore && !savedUser) {
          const response = await createUserFunction(currentUser);
          console.debug("created auth entry for user");
          uid = response.data.uid;
          setSS(`account (${currentUser.emailAddress})`, SaveStatus.Done);
        }
      } catch (e) {
        console.error(`error creating account: ${e}`);
        setSS(`account (${currentUser.emailAddress})`, SaveStatus.Error);
        setSS(`db (${currentUser.emailAddress})`, SaveStatus.Error);
        return;
      }

      const userCollection = collection(Db(), "users");

      try {
        if (!ignore && savedUser) {
          const q = query(userCollection, where("uid", "==", savedUser.uid));
          const results = await getDocs(q);
          if (results.size !== 1) {
            throw new Error(
              `did not find exactly 1 user with uid: ${savedUser.uid}`
            );
          }
          const userSnap = results.docs[0];
          await updateDoc(userSnap.ref, currentUser);
          console.debug("wrote user to DB");
          setSS(`db (${currentUser.emailAddress})`, SaveStatus.Done);
          return;
        }
      } catch (e) {
        console.error(`error adding user to db: ${e}`);
        setSS(`db (${currentUser.emailAddress})`, SaveStatus.Error);
        return;
      }

      try {
        if (!ignore && uid) {
          await addDoc(
            userCollection,
            Object.assign(
              {}, currentUser, { uid: uid, createdAt: serverTimestamp() },
            ),
          );
          console.debug("wrote user to DB");
          setSS(`db (${currentUser.emailAddress})`, SaveStatus.Done);
        }
      } catch (e) {
        console.error(`error adding user to db: ${e}`);
        setSS(`db (${currentUser.emailAddress})`, SaveStatus.Error);
      }
    }, []);

  const addToMailingList = useCallback(async (email, list, shouldIgnore) => {
    console.debug(`ensuring ${email} in ${list}`);
    let ignore = shouldIgnore();
    await ensureMemberOfList(
      {
        dryRun: FunctionsLocal || ignore,
        member: { emailAddress: email },
        mailingList: list,
      },
    );
    if (!ignore) {
      setSS(`list (${list})`, SaveStatus.Done);
    }
  }, []);

  const saveUser = useCallback((currentUser, savedUser, shouldIgnore) => {
    saveAccount(currentUser, savedUser, shouldIgnore);
    const currentLists = currentUser.mailingLists;
    const prevLists = savedUser?.mailingLists || [];
    for (const m of currentLists) {
      if (!prevLists.includes(m)) {
        addToMailingList(currentUser.emailAddress, m, shouldIgnore);
      } else {
        setSS(`list (${m})`, SaveStatus.Done);
      }
    }
    for (const m of prevLists) {
      removeFromMailingList(savedUser.emailAddress, m, shouldIgnore);
    }
  }, [addToMailingList, removeFromMailingList, saveAccount]);

  useEffect(() => {
    let ignore = false;

    if (neededOperation === "deleteUser") {
      deleteUser(savedUser, () => ignore);
    }

    else if (neededOperation === "saveUser") {
      saveUser(currentUser, savedUser, () => ignore);
    }

    return () => { ignore = true; };
  }, [neededOperation, savedUser, currentUser, deleteUser, saveUser]);

  useEffect(() => {
    let ignore = false;

    if (!saveStatus) {
      return;
    }

    let hasError = false;
    const savingDone = Object
      .values(saveStatus)
      .reduce(
        (prev, cur) => {
          hasError |= (cur === SaveStatus.Error);
          return prev && [SaveStatus.Done, SaveStatus.Error].includes(cur);
        },
        true,
      );

    if (!ignore && savingDone) {
      setTimeout(() => {
        umDispatcher({ action: "reset" });
      }, hasError ? 6_000 : 3_000);
    }

    return () => {
      ignore = true;
    };
  }, [saveStatus, umDispatcher]);

  return [userManager, umDispatcher];
}


export { useUserManagerReducer, SaveStatus };