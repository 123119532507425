import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  initializeAppCheck, ReCaptchaEnterpriseProvider
} from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const FirebaseConfig = {
  apiKey: "AIzaSyD6t_0eKQnYzc4Hs8KVYN9EWM9XWGayeZE",
  authDomain: "eei-site.firebaseapp.com",
  projectId: "eei-site",
  storageBucket: "eei-site.appspot.com",
  messagingSenderId: "828321876885",
  appId: "1:828321876885:web:235ffd380a4cc953ef049c"
};
const RecaptchaEnterpriseKey = "6Lcfa3IkAAAAANDzuNsC-0QGmy6zBkmbNXrQrqrS";
const PrimaryRegion = "us-east1";
let FunctionsLocal = false;


function parseEmulatorEnvVar(value) {
  const emulatorString = value.toString();
  const fields = emulatorString.split(":");
  if (fields.length !== 2) {
    return ["", 0];
  }
  const port = parseInt(fields[1]);
  if (port < 1 || port > (64 * 1024)) {
    console.warn(`found emulator port, but wrong value: ${fields[1]}`);
    return ["", 0];
  }
  return [fields[0], port];
}

// Initialize Firebase Emulators
const app = initializeApp(FirebaseConfig);

// Set up AppCheck, along with optional debug provider
if (process.env.REACT_APP_FIREBASE_APP_CHECK_TOKEN) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APP_CHECK_TOKEN;
}
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(RecaptchaEnterpriseKey),
  isTokenAutoRefreshEnabled: true,
});
getAnalytics(app);

if (process.env.REACT_APP_FIRESTORE_EMULATOR) {
  const [host, port] = parseEmulatorEnvVar(
    process.env.REACT_APP_FIRESTORE_EMULATOR);
  if (host !== "" && port !== 0) {
    let db = getFirestore(app);
    connectFirestoreEmulator(db, host, port);
  }
}

if (process.env.REACT_APP_AUTH_EMULATOR) {
  const [host, port] = parseEmulatorEnvVar(process.env.REACT_APP_AUTH_EMULATOR);
  if (host !== "" && port !== 0) {
    let auth = getAuth();
    connectAuthEmulator(auth, `http://${host}:${port}`);
  }
}

if (process.env.REACT_APP_FUNCTIONS_EMULATOR) {
  FunctionsLocal = true;
  const [host, port] = parseEmulatorEnvVar(
    process.env.REACT_APP_FUNCTIONS_EMULATOR);
  if (host !== "" && port !== 0) {
    let functions = getFunctions(app, PrimaryRegion);
    connectFunctionsEmulator(functions, host, port);
  }
}

if (process.env.REACT_APP_STORAGE_EMULATOR) {
  const [host, port] = parseEmulatorEnvVar(
    process.env.REACT_APP_STORAGE_EMULATOR);
  if (host !== "" && port !== 0) {
    let storage = getStorage(app);
    connectStorageEmulator(storage, host, port);
  }
}

function Db() {
  // Initialize Cloud Firestore and get a reference to the service
  return getFirestore(app);
}

function Auth() {
  // App connection to authentication emulator
  return getAuth();
}

function Storage() {
  return getStorage(app);
}

function Functions() {
  return getFunctions(app, PrimaryRegion);
}

export { Db, Auth, Storage, Functions, FunctionsLocal };
