import {
  onAuthStateChanged, signOut,
  sendEmailVerification,
  updateEmail as firebaseUpdateEmail,
  updatePassword as firebaseUpdatePassword,
} from "firebase/auth";
import {
  collection, getDocs, query, updateDoc, where,
} from "firebase/firestore";
import { createContext, useEffect, useState } from "react";

import { Db, Auth } from './FirebaseConfig';


const LoginContext = createContext(null);


async function getLoggedInUserDoc(user) {
  const usersRef = collection(Db(), "users");
  const q = query(usersRef, where("uid", "==", user.uid));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs[0];
}


function useLoginContext() {
  const [loginContext, setLoginContext] = useState({
    loggedIn: false,
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userRoles: [],
    courseAccessIds: [],
    links: {},
    studentList: [],
    claims: {},
    updateFirstName: async n => {
      const doc = await getLoggedInUserDoc(Auth().currentUser);
      console.info(`Updating first name to: ${n}`);
      console.debug(doc);

      await updateDoc(doc.ref, { firstName: n });
      console.info("Finished updating first name");

      setLoginContext(c => Object.assign({}, c, { userFirstName: n }));
    },
    updateLastName: async n => {
      const doc = await getLoggedInUserDoc(Auth().currentUser);
      console.info(`Updating last name to: ${n}`);
      console.debug(doc);

      await updateDoc(doc.ref, { lastName: n });
      console.info("Finished updating last name");

      setLoginContext(c => Object.assign({}, c, { userLastName: n }));
    },
    updateEmail: async e => {
      const currentUser = Auth().currentUser;

      const doc = await getLoggedInUserDoc(currentUser);
      console.info(`Updating email address to: ${e}`);
      console.debug(doc);

      await firebaseUpdateEmail(currentUser, e);
      await sendEmailVerification(currentUser);

      await updateDoc(doc.ref, { emailAddress: e });
      console.info("Finished updating email address");

      setLoginContext(c => Object.assign({}, c, { userEmail: e }));
    },
    updatePassword: async p => {
      await firebaseUpdatePassword(Auth().currentUser, p);
      console.info("Finished updating password.");
    },
    setCourseAccessIds: i => {
      setLoginContext(c => Object.assign({}, c, { courseAccessIds: i }));
    },
    logout: async () => { await signOut(Auth()); },
  });

  useEffect(() => {
    let ignore = false;

    const unsubscribe = onAuthStateChanged(Auth(), async (user) => {
      if (!user) {
        if (!ignore) {
          setLoginContext(c => {
            console.debug("loggedIn: false");
            return Object.assign(
              {},
              c,
              {
                loggedIn: false,
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userRoles: [],
                courseAccessIds: [],
                links: {},
                studentList: [],
                claims: {},
              },
            );
          });
        }
        return;
      }

      const token = await user.getIdTokenResult();
      console.debug(token?.claims);

      const userData = (await getLoggedInUserDoc(user)).data();

      if (!ignore) {
        setLoginContext(c => {
          console.debug("loggedIn: true");
          return Object.assign(
            {},
            c,
            {
              loggedIn: true,
              userFirstName: userData.firstName,
              userLastName: userData.lastName,
              userEmail: user.email,
              userRoles: userData.roles || [],
              courseAccessIds: userData.courseAccessIds || [],
              links: userData.links || {},
              studentList: userData.studentList || [],
              claims: token?.claims || {},
            },
          );
        });
      }
    });

    return () => {
      ignore = true;
      unsubscribe();
    };
  }, []);

  return loginContext;
}


export { LoginContext, useLoginContext };