import { doc, getDoc } from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import { pdfjs, Document, Page } from 'react-pdf';

import { Db } from "./FirebaseConfig";
import { LoginContext } from "./LoginContext";
import { UserManager } from "./UserManager";
import { useStorageUrl } from "./StorageUtils";

// Set up react-pdf
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


function Landing(props) {
  const loginContext = useContext(LoginContext);

  let message;
  let role = props.role;
  let landingPage;
  if (loginContext.loggedIn) {
    if (role === undefined || role === "") {
      message = "You are not assigned a role within this program. Please contact your Expert or Administrator for assistance.";
      landingPage = <NoLanding />
    } else {
      if (role === "consultant") {
        landingPage = <ConsultantLanding />
      }
      if (role === "parent") {
        landingPage = <ParentLanding />
      }
      if (role === "student") {
        landingPage = <StudentLanding />
      }
      if (role === "expert") {
        landingPage = <ExpertLanding
          links={loginContext.links}
          studentList={loginContext.studentList}
          claims={loginContext.claims}
        />
      }
      if (role === "admin") {
        landingPage = <AdminLanding
          links={loginContext.links}
          claims={loginContext.claims}
        />
      }
    }
  } else {
    message = "You are not logged in. Please log in to see landing.";
  }
  return (
    <div>
      <h2>
        {`Hello, ${loginContext.userFirstName}! Welcome to your ${role} portal.`}
      </h2>
      <div>
        {message}
        {landingPage}
      </div>
    </div>
  )
}

function ConsultantLanding() {
  return (
    <div>
      <p>Consultant portal features are coming soon!</p>
    </div>
  );
}

function ParentLanding() {
  return (
    <>
      <RoleLinks role="parent" />
      <Flyers prefix="parentFlyers" />
    </>
  );
}

function StudentLanding() {
  return (
    <>
      <RoleLinks role="student" />
      <Flyers prefix="studentFlyers" />
    </>
  );
}

function ExpertLanding(props) {
  return (
    <div>
      <RoleLinks role="expert" />
      <DynamicLinks links={props.links} />
      <StudentList studentList={props.studentList} />
      <UserManager claims={props.claims} />
    </div>
  );
}

function AdminLanding(props) {
  return (
    <div>
      <p>Admin portal features are coming soon!</p>
      <RoleLinks role="admin" />
      <DynamicLinks links={props.links} />
      <UserManager claims={props.claims} />
    </div>
  );
}

function RoleLinks(props) {
  const [links, setLinks] = useState({});
  useEffect(() => {
    const readLinksFromFirestore = async () => {
      const docRef = doc(Db(), "links", props.role);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        // docSnap.data() will be undefined in this case
        console.error(`Unable to load links for ${props.role}`);
        return;
      }
      setLinks(docSnap.data());
    };
    readLinksFromFirestore();
  }, [props.role]);
  return (
    <>
      <DynamicLinks links={links} />
    </>
  );
}

function DynamicLinks(props) {
  let aTags = [];
  let i = 0;
  for (const displayText in props.links) {
    aTags.push(
      <a
        className="landingLinks"
        href={props.links[displayText]}
        target="_blank"
        rel="noreferrer"
        key={`dynamicLink:${i}`}>
        {displayText}
      </a>
    );
    i += 1;
  }
  return <>{aTags}</>;
}

function StudentList(props) {
  const [studentEntries, setStudentEntries] = useState([]);
  useEffect(() => {
    const processEntries = async () => {
      let newEntries = [];
      for (const studentEntry of props.studentList) {
        if ("firestore" in studentEntry) {
          const dbEntry = await getDoc(studentEntry);
          const dbData = dbEntry.data();
          newEntries.push(dbData);
        } else {
          newEntries.push(studentEntry);
        }
      }
      setStudentEntries(newEntries);
    };
    processEntries();
  }, [props.studentList]);
  if (studentEntries.length === 0) {
    return <></>;
  }
  let tdTags = [];
  let i = 0;
  for (const studentEntry of studentEntries) {
    tdTags.push(
      <tr key={`studentEntry:${i}`}>
        <td>{studentEntry.firstName}</td>
        <td>{studentEntry.lastName}</td>
        <td>{studentEntry.emailAddress}</td>
        <td>{studentEntry.application || "(none specified)"}</td>
      </tr>
    );
    i += 1;
  }
  return (
    <div>
      <table className="studentTable">
        <caption>
          Your assigned students are listed in the table below.
        </caption>
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Application</th>
          </tr>
        </thead>
        <tbody>
          {tdTags}
        </tbody>
      </table>
    </div>
  );
}

function NoLanding() {
  return (
    <div>
      <p>You don't have access to this page.</p>
    </div>
  );
}

function Flyers(props) {
  const { prefix } = props;
  const courseToFlyer = {
    9: "Class of 2027 Webinar Series.pdf",
    10: "Class of 2026 Webinar Series.pdf",
    11: "Class of 2025 Webinar Series.pdf",
    12: "Class of 2024 Webinar Series.pdf",
  };
  const loginContext = useContext(LoginContext);
  const flyers = loginContext.courseAccessIds.map((c, i) => {
    return (
      <Flyer key={`${prefix}:flyer:${i}`} flyerName={courseToFlyer[c]} />
    );
  });

  return (
    <div className="flyers">
      {flyers}
    </div>
  );
}

function Flyer(props) {
  const { flyerName } = props;
  const flyerPath = `public/flyers/${flyerName}`;
  const [focus, setFocus] = useState(false);
  const url = useStorageUrl(flyerPath);
  if (!url) {
    return <></>;
  }

  if (!focus) {
    return (
      <div onClick={e => {
        e.preventDefault();
        setFocus(true);
      }} className="flyer">
        <Document file={url}>
          <Page width={375} height={290} pageNumber={1} />
        </Document>
      </div>
    );
  }

  const initialWidth = Math.floor(window.innerWidth * 3 / 4);
  const flyerHeight = Math.min(
    Math.floor(window.innerHeight * 3 / 4), Math.floor(initialWidth * 8.5 / 11)
  );
  const flyerWidth = Math.min(initialWidth, Math.floor(flyerHeight * 11 / 8.5));

  return (
    <div onClick={e => {
      e.preventDefault();
      setFocus(false);
    }} className="flyer withFocus">
      <Document file={url}>
        <Page width={flyerWidth} height={flyerHeight} pageNumber={1} />
      </Document>
    </div>
  );
}

export { Landing };